import dayjs from 'dayjs';
import { obterItem, removerItem, salvarItem } from '@/core/gerenciadores/GerenciadorLocalStorage';
import UtilitarioGeral from '../utilitarios/UtilitarioGeral';

export default class GerenciadorAutenticacao {
  private chaveIdentificadorParceiro = 'UP-ID-PARCEIRO';

  private chaveTokenAutenticado = 'UP-TOKEN';

  private chaveExpiracaoToken = 'UP-TOKEN-EXPIRACAO';

  public salvarIdentificadorParceiro(identificadorParceiro: string): void {
    salvarItem(this.chaveIdentificadorParceiro, identificadorParceiro);
  }

  public removerIdentificadorParceiro(): void {
    removerItem(this.chaveIdentificadorParceiro);
  }

  public salvarDadosAutenticacao(tokenAutenticacao: string, dataExpiracaoToken: string): void {
    salvarItem(this.chaveTokenAutenticado, tokenAutenticacao);
    salvarItem(this.chaveExpiracaoToken, dataExpiracaoToken);
  }

  public removerDadosAutenticacao(): void {
    removerItem(this.chaveIdentificadorParceiro);
    removerItem(this.chaveTokenAutenticado);
    removerItem(this.chaveExpiracaoToken);
  }

  public existeIdentificadorParceiro(): boolean {
    const identificadorParceiro = obterItem(this.chaveIdentificadorParceiro);
    return UtilitarioGeral.valorValido(identificadorParceiro);
  }

  public existeToken(): boolean {
    const tokenAutenticado = obterItem(this.chaveTokenAutenticado);
    return UtilitarioGeral.valorValido(tokenAutenticado);
  }

  public existeValidadeToken(): boolean {
    const dataExpiracaoToken = obterItem(this.chaveExpiracaoToken);
    return UtilitarioGeral.valorValido(dataExpiracaoToken);
  }

  public obtemTokenAutenticado():string {
    return `${obterItem(this.chaveTokenAutenticado)}`;
  }

  public obtemIdentificadorParceiro():string {
    return `${obterItem(this.chaveIdentificadorParceiro)}`;
  }

  public tokenValido(): boolean {
    if (this.existeToken()) {
      return false;
    }
    return false;
  }

  public verificaAutenticacao():boolean {
    if (!this.existeIdentificadorParceiro()) {
      return false;
    }

    if (!this.existeToken()) {
      return false;
    }

    return true;
  }

  public atualizarToken():boolean {
    let dataExpiracaoToken = obterItem(this.chaveExpiracaoToken) as any;
    dataExpiracaoToken = dayjs(dataExpiracaoToken).format('YYYY-MM-DD');
    const dataAtual = dayjs().format('YYYY-MM-DD');

    if (dataExpiracaoToken === dataAtual) {
      return true;
    }
    return false;
  }
}
